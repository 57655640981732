/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { BuildnameModalComponent } from 'src/app/components/buildname-modal/buildname-modal.component';
import { LoginComponent } from 'src/app/components/login/login.component';
import { PrivacyPolicyComponent } from 'src/app/components/privacy-policy/privacy-policy.component';
import { SaveSharePopupComponent } from 'src/app/components/save-share-popup/save-share-popup.component';
import { ApiService } from '../API/api.service';
import { CachingService } from '../cache/caching.service';
import { EventService } from '../Events/events.service';
import { WidgetsService } from '../ui/widgets.service';
import { environment } from 'src/environments/environment';
import { SaveShareNewPopupComponent } from 'src/app/components/save-share-new-popup/save-share-new-popup.component';
import { Device } from '@capacitor/device';

export const ANIMATION_TIMER = 400;

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  configurator = false;
  apiErrorMsg = 'Something went wrong!';
  colorSelected: any;
  trimSelected: any;
  selectedVariant: any;
  addedAccessories = [];
  addedAccessoriesPacks = [];
  activeTab: string;
  vehicleCode: string;
  zipCode: string;
  cpos: any;
  viewInterior = false;
  optionalCodes: any;
  mobileNumberRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
  emailRegex =
    // eslint-disable-next-line max-len
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  nameRegex = /^[ a-zA-Z\-\']+$/;
  variant_color_id: any;
  trim_color_id: any;
  buildName: any;
  customerInfo: any;
  imageAccessoryOpen = false;
  model: any;
  postcode: number;
  currency = 'en-AU'; //for australia set currency here
  currencySymbol = '$'; //for australia set currency symbol
  selectedConfigPrice: any;
  showLoadingPriceText = false;
  show_offer: any;
  idea = 4; //finalized idea number
  disableAddons = true;
  animationState = '';
  isFeatureView = false;

  constructor(
    private cachingService: CachingService,
    private apiService: ApiService,
    private widgetsService: WidgetsService,
    private platform: Platform,
    private modalController: ModalController,
    private events: EventService
  ) {}

  async startAnimation(state) {
    return new Promise((resolve, reject) => {
      if (!this.animationState) {
        this.animationState = state;
      }
      setTimeout(async () => {
        this.resetAnimationState().then((res) => {
          if (res) {
            resolve(true);
          }
        });
      }, ANIMATION_TIMER);
    });
  }

  async resetAnimationState() {
    return new Promise((resolve, reject) => {
      this.animationState = '';
      resolve(true);
    });
  }

  /**
   * Price Formatter for different currency return XXX,XXX format if price is zero or not valid
   *
   * @param price number
   * @returns formatted currency string
   */
  currencyFormatter(price) {
    const defaultCurrency = 'en-IN';
    if (this.currency === 'en-IN') {
      return price
        ? '₹ ' + Number(price).toLocaleString(this.currency)
        : '₹ XX,XX,XXX';
    } else if (this.currency === 'en-AU') {
      return price
        ? '$ ' + Number(price).toLocaleString(this.currency)
        : '$ ---,--- ';
    } else if (this.currency === 'JPY') {
      return price
        ? '¥ ' + Number(price).toLocaleString(this.currency)
        : '¥ X,XXX,XXX ';
    } else {
      return price
        ? '₹ ' + Number(price).toLocaleString(defaultCurrency)
        : '₹ XX,XX,XXX';
    }
  }

  /**
   * Price Formatter for different currency return XXX format only if price not valid, if price is 0 then returns 0
   *
   * @param price number
   * @returns formatted currency string
   */
  currencyFormatterZero(price) {
    const defaultCurrency = 'en-IN';
    if (this.currency === 'en-IN') {
      return !isNaN(price)
        ? '₹ ' + Number(price).toLocaleString(this.currency)
        : '₹ XX,XX,XXX';
    } else if (this.currency === 'en-AU') {
      return !isNaN(price)
        ? '$ ' + Number(price).toLocaleString(this.currency)
        : '$ X,XXX,XXX ';
    } else if (this.currency === 'JPY') {
      return !isNaN(price)
        ? '¥ ' + Number(price).toLocaleString(this.currency)
        : '¥ X,XXX,XXX ';
    } else {
      return !isNaN(price)
        ? '₹ ' + Number(price).toLocaleString(defaultCurrency)
        : '₹ XX,XX,XXX';
    }
  }

  sortByKey(array, key) {
    return array.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  async setConfigData() {
    return new Promise((resolve, reject) => {
      const config = {
        colorSelected: this.colorSelected,
        trimSelected: this.trimSelected,
        selectedVariant: this.selectedVariant,
        addedAccessories: this.addedAccessories,
        addedAccessoriesPacks: this.addedAccessoriesPacks,
      };
      this.cachingService.setDataInSession('config', config).then((res) => {
        resolve(true);
      });
    });
  }
  async getVariantDetails(selectedVariant, updatedUIPrice?) {
    return new Promise((resolve, reject) => {
      const requestBody = {
        variant_id: selectedVariant.variant_id,
        postcode: this.postcode,
        shown_at: 'web',
      };
      this.apiService.get('variants/details', requestBody).then(
        async (res: any) => {
          if (res.message === 'success') {
            this.selectedVariant = selectedVariant;
            this.vehicleCode = res.response?.lms_integrated_code;
            const variantDetails = res.response.variants[0];
            this.checkAddons(variantDetails);
            await this.cachingService
              .setDataInSession('variant_details', variantDetails)
              .then(() => {
                if (updatedUIPrice) {
                  console.log('updatedUIPrice event emit');
                  this.events.publish('events', {
                    updateUIPrices: selectedVariant,
                  });
                } else {
                  console.log('no updatedUIPrice');
                }
              });
            await this.cachingService.setDataInSession(
              'model_details',
              res.response
            );
            this.events.publish('events', {
              variantDetails,
            });
            this.events.publish('events', {
              variantFeatures: variantDetails,
            });
            resolve(true);
          } else {
            this.widgetsService.toastWithButtons(
              'Something went wrong!',
              'danger'
            );
            reject();
          }
        },
        (error) => {
          this.widgetsService.hideLoader();
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
          reject();
        }
      );
    });
  }

  // to check if addons are there or not in particular variant
  checkAddons(variant) {
    let accessories = variant.accessories;
    for (let index = 0; index < accessories.length; index++) {
      if (accessories[index].name.toLowerCase().includes('addons')) {
        this.disableAddons = false;
      }
    }
  }

  async getVariantAll(postcode) {
    return new Promise((resolve, reject) => {
      const requestBody = {
        model_id: this.model.model_id,
        postcode,
        shown_at: 'web',
      };
      console.log('requestBody getVariantAll', requestBody);
      this.apiService
        .get('variants/all', requestBody)
        .then(async (res: any) => {
          if (res.message === 'success') {
            await this.cachingService.setDataInLocal(
              'd_id',
              res.response.variants[0].dealer_ship_data
            );
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          // this.widgetsService.hideLoader();
          // this.widgetsService.toastWithButtons(
          //   'Something went wrong!',
          //   'danger'
          // );
          reject(error);
        });
    });
  }

  /**
   * Update prices based on postcode entered
   */
  async updateUIPrices(data) {
    console.log('updateUIPrices, old selected variant', this.selectedVariant);

    // store the updated price details of selected variant
    let updatedPriceVariant = data.res.response.variants.filter((variant) => {
      return variant.variant_id == this.selectedVariant.variant_id;
    })[0];
    console.log(
      'updateUIPrices, updated price variant details',
      updatedPriceVariant
    );

    await this.cachingService.setDataInSession('oldConfiguration', true);
    this.getVariantDetails(updatedPriceVariant, 'updatedUIPrice');
  }

  // in Aus: taxes are calculated based on color price, total added accessories price
  // call this everytime you change color or add/remove accessory to get updated total price & taxes info
  calculateColorAccTax() {
    return new Promise(async (resolve, reject) => {
      this.showLoadingPriceText = true;
      this.selectedConfigPrice = null;
      let totalAccPrice = this.addedAccessories.reduce(
        (a, b) => Number(a) + Number(b['price'] || 0),
        0
      );

      const config_id = await this.cachingService.getDataFromSession('c_id');

      const requestBody = {
        variant_cpos: this.selectedVariant?.variant_cpos,
        variant_id: this.selectedVariant?.variant_id,
        postcode: this.postcode,
        accessory_price: totalAccPrice,
        color_price: this.colorSelected?.paint_cost,
      };
      if (config_id) {
        requestBody['configuration_id'] = config_id;
      }
      console.log('requestBody calculateTax', requestBody);

      this.apiService
        .get('variants/calculateTax', requestBody)
        .then(async (res: any) => {
          if (res.message === 'success') {
            console.log('///calculateColorAccTax response', res);
            this.selectedConfigPrice = res.response;
            this.showLoadingPriceText = false;
            this.cachingService.setDataInSession(
              'selectedConfigPrice',
              res.response
            );
            // added since on summary page total price was not updating on remove of acc
            this.events.publish('events', {
              updatedTotalTaxPrices: null,
            });
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          this.widgetsService.toastWithButtons(
            'Something went wrong!',
            'danger'
          );
          reject(error);
        });
    });
  }

  configuration(crudOperation: string) {
    return new Promise(async (resolve, reject) => {
      const deviceID = await Device.getId();

      let utm = await this.cachingService.getDataFromSession('utm_params');

      const variantDetails = await this.cachingService.getDataFromSession(
        'variant_details'
      );
      const variants = await this.cachingService.getDataFromSession('variants');
      let pin_no = await this.cachingService.getDataFromSession('pin_no');
      const modelSelected = await this.cachingService.getDataFromSession(
        'modelSelected'
      );
      const config = await this.cachingService.getDataFromSession('config');
      const buildName = await this.cachingService.getDataFromSession(
        'buildName'
      );

      // const userDetails = await this.cachingService.getDataFromLocal(
      //   'userDetails'
      // );
      const visitorInfo = await this.cachingService.getDataFromLocal(
        'visitor_id'
      );
      const emi = await this.cachingService.getDataFromSession('emi_details');

      const configPacks = config?.addedAccessoriesPacks;
      const addedPacks = [];

      if (configPacks) {
        for (const pack of configPacks) {
          const packs = {
            accessories_id: pack.pack_id,
            price: pack.pack_price,
            accessory_type: 'Package',
            quantity: 1,
          };
          addedPacks.push(packs);
        }
      } else {
        console.log('no configPacks');
      }

      const addedAccessories = config?.addedAccessories;
      addedAccessories.push(...addedPacks);
      const device = this.platform.platforms();

      const requestBody = {
        visitor_id: visitorInfo ? visitorInfo : '',
        variant_id: Number(variantDetails.variant_id),
        build_name: buildName,
        brand_name: variants.brand_name,
        model_id: modelSelected.model_id,
        variant_color_id: config.colorSelected.variant_color_id,
        showroom_price: config.colorSelected?.total_price,
        page: this.configurator ? 'configurator' : 'visualizer',
        timespent: this.getTimeSpent(),
        accessories: addedAccessories,
        pin_no:
          crudOperation === 'create'
            ? null
            : pin_no && pin_no[this.vehicleCode]
            ? pin_no[this.vehicleCode]
            : null,
        device: device[0],
        device_id: deviceID.identifier,
        version_no: 'A',
        browser: this.checkBrowser(),
        source: this.configurator ? 'configurator' : 'visualizer',
        channel: 'web_configurator',
        UTMCampaign: utm ? utm.utm_campaign : null,
        UTMSource: utm ? utm.utm_medium : null,
        UTMContent: utm ? utm.utm_content : null,
        UTMMedium: utm ? utm.utm_source : null,
        country_code: '',
        is_repeat: 'new',
      };
      if (!pin_no || (pin_no && !pin_no[this.vehicleCode])) {
        crudOperation = 'create';
      }
      this.apiService.post(`configuration/${crudOperation}`, requestBody).then(
        async (data: any) => {
          if (data.message === 'success') {
            if (crudOperation === 'create') {
              const response = data.response;
              if (pin_no) {
                pin_no[this.vehicleCode] = response.pin_no;
              } else {
                pin_no = { [this.vehicleCode]: response.pin_no };
              }
              this.cachingService.setDataInSession('pin_no', pin_no);
              this.cachingService.setDataInSession(
                'c_id',
                response.configuration_id
              );
              this.cachingService.setDataInLocal(
                'visitor_id',
                response.visitor_id
              );
              this.apiService.updateReportingData('pinId', response.pin_no);
              this.apiService.sendReportingData({
                eName: 'internal',
                eCat: 'configuration',
                eAct: 'created',
              });
              this.apiService.logGAEvent(
                'uaevent',
                'pin_no',
                'Click',
                response.pin_no
              );
            }
            if (crudOperation === 'update') {
              this.apiService.sendReportingData({
                eName: 'internal',
                eCat: 'configuration',
                eAct: 'updated',
              });
            }
            if (
              config.addedAccessories.length > 0 ||
              config.addedAccessoriesPacks.length > 0
            ) {
              this.addedAccessories = config.addedAccessories;
              this.addedAccessoriesPacks = config.addedAccessoriesPacks;
            }
            this.setConfigData().then((res) => {
              resolve(true);
            });
          }
        },
        (error) => {
          this.widgetsService.toastWithButtons(this.apiErrorMsg, 'danger');
          console.error('Error : ', error);
          reject(false);
        }
      );
    });
  }

  async saveRemoveAccessory(accessory, type) {
    const pin_no = await this.cachingService.getDataFromSession('pin_no');
    const requestBody = {
      pin_no: pin_no[this.vehicleCode],
      accessories_id:
        type === 'Accessory' ? accessory.accessories_id : accessory.pack_id,
      accessory_type: type,
      price: type === 'Accessory' ? accessory.price : accessory.pack_price,
      quantity: type === 'Accessory' ? accessory.quantity : 1,
    };
    this.widgetsService.showLoader();
    this.apiService.post('configuration/accessory/save', requestBody).then(
      async (data: any) => {
        this.widgetsService.hideLoader();
        if (data.message === 'success') {
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(this.apiErrorMsg, 'danger');
        console.error('Error : ', error);
      }
    );
  }
  getTimeSpent() {
    const timer = performance.now();
    const counter = Math.round(timer / 1000);
    const sec = counter;
    let hours: any = Math.floor(sec / 3600);
    let minutes: any = Math.floor((sec - hours * 3600) / 60);
    let seconds: any = sec - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    const timeSpent = hours + ':' + minutes + ':' + seconds;
    // return timespent // commented as total seconds is req now : so instead of formatting it, directly return counter string
    return String(counter);
  }

  checkBrowser() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      return 'Edge';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else {
      return 'unknown';
    }
  }

  async openBuildNameModal(action) {
    let buttonText: string;
    if (action === 'save') {
      buttonText = 'SAVE';
    } else if (action === 'proceedToBook') {
      buttonText = 'SAVE & PROCEED';
    }
    const modal = await this.modalController.create({
      component: BuildnameModalComponent,
      componentProps: {
        buttonText,
        action,
      },
      backdropDismiss: false,
      cssClass: 'one3d-build-name-popup',
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.openLoginModal(action);
    }
  }

  async openLoginModal(action) {
    this.widgetsService.showLoader();
    this.calculateColorAccTax()
      .then(() => {
        this.widgetsService.hideLoader();
        this.saveConfiguration(action);
      })
      .catch((err) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(this.apiErrorMsg, 'danger');
      });

    // this.openShareModal();
    // let userDetails = await this.cachingService.getDataFromLocal('userDetails');

    // use firstName & email to verify is user logged in or not
    // if (userDetails?.first_name && userDetails?.email) {
    //   this.saveConfiguration(action);
    // } else {
    //   this.saveConfiguration('update');
    //   const modal = await this.modalController.create({
    //     component: LoginComponent,
    //     componentProps: {
    //       contentData: {
    //         header: 'SAVE & SHARE',
    //         msgContent:
    //           'Saving your build will ensure you have easy access to it, anywhere, anytime.',
    //         from: 'cta',
    //         action: action,
    //       },
    //     },
    //     backdropDismiss: false,
    //     cssClass: 'one3d-login-modal auto-height',
    //     animated: false,
    //   });
    //   await modal.present();
    //   const { data } = await modal.onDidDismiss();
    //   console.log('data', data);

    //   if (data == 'success') {
    //     console.log('save');

    //     this.saveConfiguration(action);
    //   }
    // }
  }

  async saveConfiguration(action?) {
    console.log('saveConfiguration');

    const variantDetails = await this.cachingService.getDataFromSession(
      'variant_details'
    );
    const deviceID = await Device.getId();

    if (action === 'proceedToBook') {
      this.apiService.logGAEvent(
        'uaevent',
        'Content',
        'Click',
        'Save and Book'
      );
      this.apiService.sendReportingData({
        eName: 'click',
        eCat: 'cta_clicked_configurator',
        eAct: 'proceed_to_book',
      });
    }
    const variants = await this.cachingService.getDataFromSession('variants');
    const pin_no = await this.cachingService.getDataFromSession('pin_no');
    const modelSelected = await this.cachingService.getDataFromSession(
      'modelSelected'
    );
    const buildName = await this.cachingService.getDataFromSession('buildName');
    const config = await this.cachingService.getDataFromSession('config');
    const utm = await this.cachingService.getDataFromSession('utm_params');
    const visitorInfo = await this.cachingService.getDataFromLocal(
      'visitor_id'
    );
    const config_id = await this.cachingService.getDataFromSession('c_id');
    const configPacks = config?.addedAccessoriesPacks;
    const addedPacks = [];
    const oldConfiguration = await this.cachingService.getDataFromSession(
      'oldConfiguration'
    );

    const emi = await this.cachingService.getDataFromSession('emi_details');

    if (configPacks) {
      for (const pack of configPacks) {
        const packs = {
          accessories_id: pack.pack_id,
          price: pack.pack_price,
          accessory_type: 'Package',
          quantity: 1,
        };
        addedPacks.push(packs);
      }
    } else {
      console.log('no configPacks');
    }
    const device = this.platform.platforms();
    const addedAccessories = config?.addedAccessories;
    addedAccessories.push(...addedPacks);
    const requestBody = {
      visitor_id: visitorInfo ? visitorInfo : '',
      variant_id: Number(config.selectedVariant.variant_id),
      build_name: buildName,
      brand_id: variants.brand_id,
      model_id: modelSelected.model_id,
      variant_color_id: config.colorSelected.variant_color_id,
      trim_color_id: config.trimSelected
        ? config.trimSelected.variant_color_id
        : null,
      showroom_price: this.selectedConfigPrice?.drive_away_price,
      page: this.configurator ? 'configurator' : 'visualizer',
      timespent: this.getTimeSpent(),
      accessories: addedAccessories,
      kit: [],
      pin_no:
        pin_no && pin_no[this.vehicleCode] ? pin_no[this.vehicleCode] : null,
      configuration_id: config_id ? config_id : null,
      device: device[0],
      device_id: deviceID.identifier,
      browser: this.checkBrowser(),
      UTMCampaign: utm ? utm.utm_campaign : null,
      UTMSource: utm ? utm.utm_medium : null,
      UTMContent: utm ? utm.utm_content : null,
      UTMMedium: utm ? utm.utm_source : null,
      channel: 'web_configurator',
      country_code: '',
      FinanceOptions: {
        ExShowroomPrice: emi ? emi.ExShowroomPrice : null,
        DownPayment: emi ? emi.DownPayment : null,
        TradeInValue: emi ? emi.TradeInValue : null,
        EstimatedAmountFinanced: emi ? emi.EstimatedAmountFinanced : null,
        TermDuration: emi ? emi.TermDuration : null,
        AnnualPercentageRate: emi ? emi.AnnualPercentageRate : null,
        EstimatedMonthlyPayment: emi ? emi.EstimatedMonthlyPayment : null,
      },
    };
    this.apiService.put('configuration/save', requestBody).then(
      async (data: any) => {
        // this.widgetsService.hideLoader();
        if (data.message === 'success') {
          if (action === 'save') {
            this.openShareModal();
          } else if (action === 'proceedToBook') {
            this.proceedToBook();
          } else {
            console.log('configuration updated!!');
          }
        } else {
          console.error('Error');
        }
      },
      (error) => {
        this.widgetsService.hideLoader();
        this.widgetsService.toastWithButtons(this.apiErrorMsg, 'danger');
        console.error('Error : ', error);
      }
    );
  }
  async openShareModal() {
    const modal = await this.modalController.create({
      component: SaveShareNewPopupComponent,
      backdropDismiss: false,
      cssClass: 'one3d-save-share-popup',
    });

    await modal.present();
  }
  async proceedToBook() {
    this.apiService.logGAEvent(
      'uaevent',
      'Content',
      'Click',
      'Redirect to BMO page'
    );

    let proceedToBookUrl =
      'https://www.jeep.com.au/get-a-quote.html?vehicle=grand-cherokee-5-7&year=2023';

    window.open(proceedToBookUrl, '_parent');
  }

  async openPrivacyPolicy() {
    const modal = await this.modalController.create({
      component: PrivacyPolicyComponent,
      animated: false,
      backdropDismiss: false,
      cssClass: 'one3d-privacy-policy-modal',
    });

    await modal.present();
    if (window.location.pathname.includes('visualizer')) {
      this.apiService.sendReportingData({
        eName: 'click',
        eCat: 'privacy_button_visualizer',
        eAct: 'privacy_button_visualizer',
      });
    } else {
      this.apiService.sendReportingData({
        eName: 'click',
        eCat: 'privacy_button_configurator',
        eAct: 'privacy_button_configurator',
      });
    }
  }

  getParentOrigin() {
    const locationAreDisctint = window.location !== window.parent.location;
    const parentOrigin = (
      (locationAreDisctint ? document.referrer : document.location) || ''
    ).toString();

    if (parentOrigin) {
      return new URL(parentOrigin).origin;
    }

    const currentLocation = document.location;

    if (
      currentLocation.ancestorOrigins &&
      currentLocation.ancestorOrigins.length
    ) {
      return currentLocation.ancestorOrigins[0];
    }

    return '';
  }
  inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  clearCacheForFinanceCalculators() {
    this.cachingService.setDataInSession('emi_details', null);
    this.cachingService.setDataInSession('extendedWarranty_details', null);
    this.cachingService.setDataInSession('exchange_values', null);
    this.cachingService.setDataInSession('amc_details', null);
  }
  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile';
    }
    return 'desktop';
  }

  // To get configurator navigating url according to the model selected
  getNavigateConfiguratorUrl(modelDetails) {
    let url = '';

    if (modelDetails.model_code === 'COMPASS') {
      url = '/new-compass/3d-configurator.html/';
    } else if (modelDetails.model_code === 'GRAND_CHEROKEE_NEW') {
      url = '/new-grand-cherokee/3d-configurator.html/';
    } else {
      url = '/new-jeep-meridian/3d-configurator.html/';
    }
    return url;
  }

  // To get Test drive url according to the model selected
  getTestDriveUrl() {
    let testDriveUrl = '';

    if (
      this.model.model_code == 'GC_AUSTRALIA_3_ROW' &&
      this.selectedVariant.num_of_seats === '7 Seater'
    ) {
      testDriveUrl =
        'https://www.jeep.com.au/schedule-a-test-drive.html?vehicle=grand-cherokee-l';
    } else if (
      this.model.model_code == 'GC_AUSTRALIA_3_ROW' &&
      this.selectedVariant.num_of_seats === '5 Seater'
    ) {
      testDriveUrl =
        'https://www.jeep.com.au/schedule-a-test-drive.html?vehicle=grand-cherokee';
    } else if (this.model.model_code == 'wrangler') {
      testDriveUrl =
        'https://www.jeep.com.au/schedule-a-test-drive.html?vehicle=New%20Wrangler%20JL';
    } else if (this.model.model_code == 'avenger') {
      testDriveUrl =
        'https://www.jeep.com.au/schedule-a-test-drive.html?vehicle=avenger';
    } else {
      testDriveUrl = 'https://www.jeep.com.au/schedule-a-test-drive.html';
    }
    return testDriveUrl;
  }
}
